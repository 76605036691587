import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {
    }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),
            new AppMenuItem('PositionManagement', [
                'Pages.Nominations',
                'Pages.NetBilateralContractManagement',
                'Pages.ConsumptionForecast',
                'Pages.FlexiblePosition',
                'Pages.DayAheadMarketManagement'
            ], 'flaticon-app', '/app/admin/editions', [], [
                new AppMenuItem('ForecastManagement', ['Pages.Nominations'], 'flaticon-app', '/app/admin/editions', [], [
                    new AppMenuItem('Nominations', 'Pages.Nominations', 'flaticon-more', '/app/main/dispatch/nominations'),
                ]),
                new AppMenuItem('NetPositionManagement', [
                    'Pages.NetBilateralContractManagement',
                    'Pages.ConsumptionForecast',
                    'Pages.FlexiblePosition',
                    'Pages.DayAheadMarketManagement'
                ], 'flaticon-app', null, [], [
                    new AppMenuItem('NetBilateralContractManagement', 'Pages.NetBilateralContractManagement', 'flaticon-more', '/app/main/scheduling/net-bilateral-contract-managements'),
                    new AppMenuItem('ConsumptionForecast', 'Pages.ConsumptionForecast', 'flaticon-more', '/app/main/scheduling/consumption-forecast'),
                    new AppMenuItem('FlexiblePositions', 'Pages.FlexiblePosition', 'flaticon-more', '/app/main/scheduling/flexible-position'),
                    new AppMenuItem('DayAheadMarketManagement', 'Pages.DayAheadMarketManagement', 'flaticon-more', '/app/main/scheduling/day-ahead-market-management'),
                ]),
            ]),
            new AppMenuItem('DayAheadMarket', [
                'Pages.HourlyBids',
                'Pages.BilateralContracts',
                'Pages.Availability',
                'Pages.FinalDailyGenerationScheduling',
                'Pages.HourlyBidResults',
                'Pages.FinalDailyGenerationScheduling'
            ], 'flaticon-app', null, [], [
                new AppMenuItem('EpiasOperations', ['Pages.HourlyBids', 'Pages.BilateralContracts'], 'flaticon-app', '/app/admin/editions', [], [
                    new AppMenuItem('HourlyBidSending', 'Pages.HourlyBids', 'flaticon-more', '/app/main/dispatch/hourly-bid-sending'),
                    new AppMenuItem('BilateralContracts', 'Pages.BilateralContracts', 'flaticon-more', '/app/main/dispatch/bilateral-contracts'),
                ]),
                new AppMenuItem('TEIASOperations', ['Pages.Availability', 'Pages.FinalDailyGenerationScheduling'], 'flaticon-app', '/app/admin/editions', [], [
                    new AppMenuItem('Availabilities', 'Pages.Availability', 'flaticon-more', '/app/main/dispatch/availability'),
                    new AppMenuItem('FinalDailyGenerationScheduling', 'Pages.FinalDailyGenerationScheduling', 'flaticon-more', '/app/main/dispatch/final-daily-generation-scheduling'),
                ]),
                new AppMenuItem('MarketResults', ['Pages.HourlyBidResults', 'Pages.FinalDailyGenerationScheduling'], 'flaticon-app', '/app/admin/editions', [], [
                    new AppMenuItem('DayAheadMarketResult', 'Pages.HourlyBidResults', 'flaticon-more', '/app/main/dispatch/hourly-bid-results'),
                ]),
            ]),
            new AppMenuItem('IntradayMarket', [
                'Pages.Intraday',
                'Pages.IntradayWithInGroupSettings',
                'Pages.IntradayWithInGroupSettings'
            ], 'flaticon-app', null, [], [
                new AppMenuItem('Trading', ['Pages.Intraday'], 'flaticon-app', '/app/admin/editions', [], [
                    new AppMenuItem('IntradayMenu', 'Pages.Intraday', 'flaticon-more', '/app/main/intraday'),
                ]),
                new AppMenuItem('IntradayWithinGroupManagement', ['Pages.IntradayWithInGroupSettings', 'Pages.IntradayWithInGroupSettings'], 'flaticon-app', '/app/admin/editions', [], [
                    new AppMenuItem('IntradayWithinGroupAndFdgsRevisionSettings', 'Pages.IntradayWithInGroupSettings', 'flaticon-more', '/app/main/intraday-within-group-fdgs-revision-settings'),
                    new AppMenuItem('IntradayWithinGroupAndFdgsRevisionAnalysis', 'Pages.IntradayWithInGroupSettings', 'flaticon-more', '/app/main/intraday-within-group-fdgs-revision-analysis'),
                ]),
                new AppMenuItem('IntradayTag', 'Pages.IntradayUserTag', 'flaticon-more', '/app/main/intraday-tags'),
            ]),
            new AppMenuItem('Automation', [
                'Pages.OperationBots',
                'Pages.Intraday',
                'Pages.DayAheadJobPlanning',
                'Pages.Alert'
            ], 'flaticon-app', null, [], [
                new AppMenuItem('BotManagement', ['Pages.OperationBots', 'Pages.Intraday'], 'flaticon-app', '/app/admin/editions', [], [
                    new AppMenuItem('IntradayBotLibraries', 'Pages.IntradayBotLibraries', 'flaticon-more', '/app/main/intraday-bot-libraries'),
                    new AppMenuItem('TradeBotSchedulers', 'Pages.IntradayBots', 'flaticon-more', '/app/main/bot-schedulers'),
                    new AppMenuItem('TradeBots', 'Pages.IntradayBots', 'flaticon-more', '/app/main/intraday-bots'),
                    new AppMenuItem('OperationBots', 'Pages.OperationBots', 'flaticon-more', '/app/main/operation-bots'),
                ]),
                new AppMenuItem('AutomatedNotifications', ['Pages.DayAheadJobPlanning'], 'flaticon-app', '/app/admin/editions', [], [
                    new AppMenuItem('ScheduledNotifications', 'Pages.DayAheadJobPlanning', 'flaticon-alarm', '/app/main/day-ahead-job-planning'),
                ]),
                new AppMenuItem('HealthCheck', ['Pages.Alert'], 'flaticon-app', '/app/admin/editions', [], [
                    new AppMenuItem('Alerts', 'Pages.Alert', 'flaticon-alarm', '/app/main/alert-rules'),
                ]),
            ]),
            new AppMenuItem('Analysis', ['Pages.Mep'], 'flaticon-app', null, [], [
                new AppMenuItem('PriceAndDirectionForecast', ['Pages.Mep'], 'flaticon-app', '/app/admin/editions', [], [
                    new AppMenuItem('Meps', 'Pages.Mep', 'flaticon-more', '/app/main/dispatch/meps'),
                ]),
                new AppMenuItem('Reporting', ['Pages.Report'], 'flaticon-app', '/app/admin/editions', [], [
                    new AppMenuItem('Trading Report', 'Pages.Intraday.Report', 'flaticon-more', '/app/main/trading-report'),
                ]),
            ]),
            new AppMenuItem('Administration', [
                'Pages.Customers',
                'Pages.Companies',
                'Pages.PowerPlants',
                'Pages.Administration.Roles',
                'Pages.Counterparties',
            ], 'flaticon-app', null, [], [
                new AppMenuItem('OnBoarding', [
                    'Pages.Customers',
                    'Pages.Companies',
                    'Pages.PowerPlants',
                    'Pages.Administration.Roles',
                    'Pages.Counterparties',
                ], 'flaticon-app', '/app/admin/editions', [], [
                    new AppMenuItem('Customers', 'Pages.Customers', 'flaticon-more', '/app/main/customers'),
                    new AppMenuItem('Companies', 'Pages.Companies', 'flaticon-more', '/app/main/companies'),
                    new AppMenuItem('PowerPlants', 'Pages.PowerPlants', 'flaticon-more', '/app/main/powerplants'),
                    new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-more', '/app/admin/roles'),
                    new AppMenuItem('Counterparties', 'Pages.Counterparties', 'flaticon-more', '/app/main/counterparties/counterparties'),
                ]),
            ]),
            new AppMenuItem('SuperAdmin', [
                'Pages.MarketGroups',
                'Pages.Administration.Users',
                'Pages.Administration.Roles',
                'Pages.Administration.OrganizationUnits',
                'Pages.Administration.Languages',
                'Pages.Administration.AuditLogs',
                'Pages.Administration.Host.Maintenance',
                'Pages.Administration.Tenant.SubscriptionManagement',
                'Pages.Administration.UiCustomization',
                'Pages.Administration.UiCustomization',
                'Pages.Administration.WebhookSubscription',
                'Pages.Administration.DynamicProperties',
                'Pages.Administration.Tenant.Settings',
                'Pages.Administration.MassNotification',
                'Pages.ValueSources'
            ], 'flaticon-app', null, [], [
                new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                new AppMenuItem('MarketGroups', 'Pages.MarketGroups', 'flaticon-more', '/app/main/market-groups'),
                new AppMenuItem('Organization Units', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages', ['/app/admin/languages/{name}/texts']),
                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
                new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('WebhookSubscriptions', 'Pages.Administration.WebhookSubscription', 'flaticon2-world', '/app/admin/webhook-subscriptions'),
                new AppMenuItem('DynamicProperties', 'Pages.Administration.DynamicProperties', 'flaticon-interface-8', '/app/admin/dynamic-property'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings'),
                new AppMenuItem('ValueSources', 'Pages.ValueSources', 'flaticon-more', '/app/main/value-sources'),
                new AppMenuItem('Notifications', '', 'flaticon-alarm', '', [], [
                        new AppMenuItem(
                            'Inbox',
                            '',
                            'flaticon-mail-1',
                            '/app/notifications'
                        ),
                        new AppMenuItem(
                            'MassNotifications',
                            'Pages.Administration.MassNotification',
                            'flaticon-paper-plane',
                            '/app/admin/mass-notifications'
                        )
                    ]
                )
            ]),
        ])
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this.isPermissionGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }

    private isPermissionGranted(permissions: string | string[]) {
        if (Array.isArray(permissions)) {
            return permissions.some(permission => this._permissionCheckerService.isGranted(permission));
        }

        return this._permissionCheckerService.isGranted(permissions)
    }
}
